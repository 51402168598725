<template>
  <div class="from">
    <el-form :model="model" :rules="rules" ref="ctlForm" size="small" label-width="100px" class="demo-ruleForm">
      <el-form-item label="拍照模式" prop="photoType">
        <el-radio-group v-model="model.photoType" @input="handleCheckRadio">
          <el-radio :label="item.value" border v-for="item of photoTypes" :key="item.value">{{ item.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="有效日期" prop="dataSlot">
        <div class="termTime_box">
          <el-date-picker v-model="model.dataSlot" type="daterange" align="right" value-format="yyyy-MM-dd"
            format="yyyy 年 MM 月 dd 日" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            :clearable="false" @change="timeChange('dayBut')">
          </el-date-picker>
          <el-button :type="butStutas.dayBut" @click="allDay" size="mini" class="allDay_but" plain
            icon="el-icon-check">长期执行</el-button>
        </div>
      </el-form-item>
      <el-form-item label="有效时间" prop="timeSlot">
        <div class="termTime_box">
          <el-time-picker v-model="model.timeSlot" value-format="HH:mm" format="HH:mm" range-separator="至"
            start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" :clearable="false" is-range
            @change="timeChange('timeBut')">
          </el-time-picker>
          <el-button :type="butStutas.timeBut" @click="allTimes" size="mini" class="allDay_but" plain
            icon="el-icon-check">全天</el-button>
        </div>
      </el-form-item>
      <el-form-item label="时间选择" prop="week">
        <week v-model="model.week"></week>
      </el-form-item>
      <el-form-item label="执行频率" prop="loopTime" key="loopTime">
        <el-tooltip class="item" effect="dark" :content="timeTooltipContent" placement="top">
          <el-input placeholder="请输入时间" v-model.number="model.loopTime" type="number" class="input-with-select"
            style="width: 350px">
            <el-select v-model="mhTime" slot="prepend" placeholder="请选择" @change="timeTypeChange" style="width: 80px">
              <el-option label="分" value="m"></el-option>
              <el-option label="小时" value="h"></el-option>
            </el-select>
            <span slot="suffix" class="unit_box">{{ mhTime == "h" ? "小时" : "分钟" }}</span>
          </el-input>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="预置点位" prop="pointId" v-if="model.photoType == 3">
        <el-select v-model="model.pointId" placeholder="请选择预置点位" multiple class="point_select">
          <el-option v-for="item in precutList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-switch v-model="model.isEnabled" :active-color="$themeColor" inactive-color="#343F48"> </el-switch>
        <span :class="['switch_text', model.isEnabled ? 'baseColor' : '']">{{ model.isEnabled ? "启用" : "停用" }}</span>
      </el-form-item>
      <el-form-item class="but_box">
        <el-button type="warning" plain @click="resetForm" size="mini" icon="el-icon-close">取消</el-button>
        <el-button type="primary" plain @click="submitForm" size="mini" icon="el-icon-check">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import bus from '@/libs/eventBus';
import { mapTo } from "@/libs/util";
import cronMixin from "@/mixins/cronMixin.js";
import week from "@/components/localComponent/week.vue";
export default {
  props: {
    devId: String,
    devType: String
  },
  components: {
    week,
  },
  mixins: [cronMixin],
  data () {
    let that = this;
    const nowDate = that.$day().format("YYYY-MM-DD");
    return {
      model: {
        id: "",
        devId: "",
        photoCorn: "",
        isEnabled: true,
        bTime: "",
        eTime: "",
        bDate: "",
        eDate: "",
        photoType: 1,
        pointId: [],
        week: [],
        loopTime: "",
        dataSlot: [nowDate, "2099-01-01"],
        timeSlot: ["00:00", "23:59"],
      },
      precutList: [],
      nowDate: nowDate,
      mhTime: "m",
      timeTooltipContent: "请输入5 ~ 59的分钟范围",
      butStutas: {
        dayBut: "primary",
        timeBut: "primary",
      },
    }
  },
  computed: {
    photoTypes () {
      let list = this.$dictionary("photoTypes");
      if (this.devType == "100402") return list;
      else {
        let slicedArr = list.slice(0, 1);
        return slicedArr;
      }
    },
    rules () {
      let timeRange = (rule, value, callback) => {
        // if (!value) {
        //   callback(new Error("请输入执行频率"));
        // }
        if (this.mhTime == "m") {
          if (value >= 5 && value <= 59) {
            callback();
          } else {
            callback(new Error("请输入5 ~ 59的分钟范围"));
          }
        } else {
          if (value >= 1 && value <= 23) {
            callback();
          } else {
            callback(new Error("请输入1 ~ 23的小时范围"));
          }
        }
      };
      const arrayEmpty = (rule, value, callback) => {
        if (!Array.isArray(value)) callback(new Error("数据格式为数组"));
        if (value.length) {
          callback();
        } else {
          callback(new Error("请选择日期"));
        }
      };
      let photoType = this.model.photoType;
      if (photoType == 3) {
        return {
          timeSlot: [{ required: true, message: "请选择有效日期期", trigger: "change" }],
          dataSlot: [{ required: true, message: "请选择有效时间", trigger: "change" }],
          photoType: [{ required: true, message: "请选择拍照模式", trigger: "change" }],
          pointId: [{ required: true, message: "请选择预置点位", trigger: "change" }],
          loopTime: [{ validator: timeRange, trigger: "change", required: true }],
          week: [{ validator: arrayEmpty, trigger: "change", required: true }],
        };
      } else {
        return {
          timeSlot: [{ required: true, message: "请选择有效日期期", trigger: "change" }],
          dataSlot: [{ required: true, message: "请选择有效时间", trigger: "change" }],
          photoType: [{ required: true, message: "请选择拍照模式", trigger: "change" }],
          loopTime: [{ validator: timeRange, trigger: "change", required: true }],
          week: [{ validator: arrayEmpty, trigger: "change", required: true }],
        };
      }
    },
  },
  created () {
    this.reqIotVideoPhotoSetList()
    this.reqGetPrecutList()
  },
  methods: {
    // 获取视频设备配置
    async reqIotVideoPhotoSetList () {
      let list = await this.$api.IotVideoPhotoSetList({ devId: this.devId });
      if (list && list.length) {
        mapTo(list[0], this.model);
      } else {
        this.model = {
          id: "",
          devId: "",
          photoCorn: "",
          isEnabled: true,
          bTime: "00:00",
          eTime: "23:59",
          bDate: this.nowDate,
          eDate: "2099-01-01",
          photoType: 1,
          pointId: [],
          week: [],
          loopTime: "",
          dataSlot: [this.nowDate, "2099-01-01"],
          timeSlot: ["00:00", "23:59"],
        };
      }
      this.analysisCron();
    },

    // 获取预置点位列表
    async reqGetPrecutList () {
      const res = await this.$api.GetPrecutList({ devId: this.devId });
      this.precutList = res || [];
    },

    // 执行频率切换
    timeTypeChange (val) {
      if (val == "m") {
        this.model.loopTime = null;
        this.timeTooltipContent = "请输入5 ~ 59的分钟范围";
      } else {
        this.model.loopTime = null;
        this.timeTooltipContent = "请输入1 ~ 23的小时范围";
      }
    },
    // 拍照模式切换
    handleCheckRadio () {
      this.model.pointId = null;
    },

    // 整合数据
    mergeData () {
      this.weeksDataToCron(this.model.week);
      this.MHDataToCron(this.model.loopTime, this.mhTime);
      let [bTime, eTime] = this.model.timeSlot;
      let [bDate, eDate] = this.model.dataSlot;
      this.model.photoCorn = this.cronStr;
      this.model.bDate = bDate;
      this.model.eDate = eDate;
      this.model.bTime = bTime;
      this.model.eTime = eTime;
      this.model.devId = this.devId;
    },
    // 解析数据
    analysisCron () {
      let cron = this.model.photoCorn;
      if (cron) {
        let week = this.weeksCronToData(cron);
        let loopTimeData = this.MHCronToData(cron);
        this.$set(this.model, "week", week);
        if (loopTimeData) {
          this.$set(this.model, "loopTime", loopTimeData.data);
          this.mhTime = loopTimeData.type;
        }
      }
      this.model.dataSlot = [this.model.bDate, this.model.eDate];
      this.model.timeSlot = [this.model.bTime, this.model.eTime];
      // 日期显示
      let eT = this.model.eTime.slice(0, 5);
      let bT = this.model.bTime.slice(0, 5);
      let eD = this.model.eDate.slice(0, 10);
      if (eT !== "23:59" && bT !== "00:00") this.butStutas.timeBut = "";
      else this.butStutas.timeBut = "primary";
      if (eD !== "2099-01-01") this.butStutas.dayBut = "";
      else this.butStutas.dayBut = "primary";
    },
    //清除按钮选中效果
    timeChange (name) {
      this.butStutas[name] = "";
    },

    // 全天
    allTimes () {
      this.$set(this.model, "timeSlot", ["00:00", "23:59"]);
      this.butStutas.timeBut = "primary";
    },

    // 长期执行
    allDay () {
      this.model.dataSlot = [this.nowDate, "2099-01-01"];
      this.butStutas.dayBut = "primary";
    },
    resetForm () {
      this.$refs.ctlForm.resetFields();
      bus.$emit('colseDiolg')
    },
    submitForm () {
      this.$refs.ctlForm.validate((valid) => {
        if (valid) {
          this.mergeData();
          let api = this.model.id ? "EditIotVideoPhotoSet" : "AddIotVideoPhotoSet";
          this.$api[api](this.model).then((res) => {
            if (res) {
              this.$refs.ctlForm.resetFields();
              bus.$emit('colseDiolg')
              this.$message({
                message: "保存成功",
                type: "success",
              });
            }
          }).catch((err) => {
            return
          });
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.from {
  ::v-deep .el-radio {
    margin-right: 20px;
  }

  ::v-deep .el-switch__core {
    background: none;
    border: none;
  }

  .termTime_box {
    display: flex;
    align-items: center;

    .allDay_but {
      margin-left: 10px;
    }
  }

  .input-with-select {
    ::v-deep .el-input-group__prepend {
      background: #344552;
      border: none;
      border-right: 1px solid #999999;
    }
  }

  .switch_text {
    margin-left: 12px;
    color: #999;
  }

  .unit_box {
    display: block;
    margin-top: 6px;
  }

  ::v-deep .el-select {
    width: 350px;
  }

  .but_box {
    text-align: right;
    margin-bottom: 0 !important;
  }
}
</style>